import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import Projects from '../components/Projects';
import Services from '../components/Services';
import Clients from '../components/Clients';
import Features from '../components/Features';
import Tools from '../components/Tools';

export default function Index({ data }) {
  const { hero } = data.sanityHomepage;
  const aboutText = data.sanityHomepage.aboutBlurb;
  const serviceText = data.sanityHomepage.serviceBlurb;
  const { seo } = data.sanityHomepage;

  return (
    <Layout loadingTexts onLoadAnimations={[true, true]}>
      <SEO title={seo.title} description={seo.description} />

      <Hero
        title1="The future is"
        title2="Unbuilt."
        img={hero.asset.gatsbyImageData}
        alt={hero.alt}
        aboutText={aboutText}
      />
      <Projects />
      <Services serviceText={serviceText} />
      <div className="inverse-colors" data-scroll-section>
        <Clients />
        <Features />
        <Tools />
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    sanityHomepage {
      hero {
        alt
        asset {
          gatsbyImageData
        }
      }
      aboutBlurb
      serviceBlurb
      seo {
        title
        description
      }
    }
  }
`;
