import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import gsap from 'gsap';

export default function Features() {
  useEffect(() => {
    const featureSlides = document.querySelectorAll('#our-work-is .feature');
    const featureTime = 10000;
    const auto = true;
    let slideInterval;
    featureSlides[0].classList.add('current');

    const nextFeatureAnim = () => {
      const featureLoader = document.querySelectorAll(
        '#our-work-is .feature.current .feature-loader'
      );
      gsap.set(featureLoader, { animation: `none` });

      gsap.fromTo(
        featureLoader,
        { width: 0 },
        {
          duration: featureTime / 1000,
          width: '100%',
          ease: 'none',
        }
      );

      gsap.fromTo(
        '.feature.current h3 .inner',
        {
          y: '100%',
          rotationX: -90,
          opacity: 0,
        },
        {
          duration: 0.8,
          y: '0%',
          rotationX: 0,
          opacity: 1,
          ease: 'power2.out',
        }
      );

      gsap.fromTo(
        '.feature.current p',
        { y: 40, opacity: 0 },
        {
          duration: 0.8,
          delay: 0.3,
          y: 0,
          opacity: 0.8,
          ease: 'power2.out',
        }
      );
    };

    const nextFeature = () => {
      const current = document.querySelector('#our-work-is .feature.current');
      current.classList.remove('current');

      if (current.nextElementSibling) {
        current.nextElementSibling.classList.add('current');
      } else {
        featureSlides[0].classList.add('current');
      }
      setTimeout(() => {
        current.classList.remove('current');
      });

      nextFeatureAnim();
    };

    if (auto) {
      slideInterval = setInterval(nextFeature, featureTime);
    }

    return () => {
      clearInterval(slideInterval);
    };
  }, []);

  const data = useStaticQuery(graphql`
    {
      allSanityFeatures(sort: { fields: order }) {
        nodes {
          title
          desc
        }
      }
    }
  `);
  const allFeatures = data.allSanityFeatures.nodes;

  return (
    <>
      <section id="our-work-is">
        <div className="wrapper">
          <h2>
            <div className="inner build-reveal">Our Work is</div>
          </h2>
          <div className="all-features">
            {allFeatures.map((feature, i) => (
              <div className="feature" key={i}>
                <h3 className="element-reveal">
                  <div className="inner">{feature.title}</div>
                  <span className="feature-loader" />
                </h3>
                <p className="split-lines">{feature.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
