import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function Clients() {
  const data = useStaticQuery(graphql`
    {
      allSanityClients(sort: { fields: order }) {
        nodes {
          title
          logo {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const allClients = data.allSanityClients.nodes;

  return (
    <>
      <div className="our-clients">
        <div className="wrapper">
          <div className="all-clients stagger-reveal">
            {allClients.map((client, i) => (
              <div className="img" key={i}>
                <GatsbyImage
                  image={client.logo.asset.gatsbyImageData}
                  alt={client.title}
                  objectFit="contain"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
