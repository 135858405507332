import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function Hero({
  title1,
  title2,
  tag = true,
  img,
  alt,
  aboutText,
}) {
  return (
    <>
      <div className="hero" data-scroll-section>
        <div className="wrapper">
          <div className="hero-texts">
            <h1>
              <div className="main-text future">
                <div className="inner">{title1}</div>
              </div>
              <div className="main-text unbuilt">
                <div className="inner">
                  <div className="hero-marquee">
                    <div>{title2}</div>
                    <div>{title2}</div>
                    <div>{title2}</div>
                    <div>{title2}</div>
                    <div>{title2}</div>
                  </div>
                </div>
              </div>
            </h1>
            {tag && (
              <p id="tag">
                <span>A web design agency</span>
              </p>
            )}
          </div>

          <div className="hero-img">
            <div className="mask" />
            <div className="inner">
              <GatsbyImage
                image={img}
                loading="eager"
                alt={alt}
                className="hero-bg"
              />
            </div>
          </div>

          {aboutText && (
            <div className="hero-about">
              <p className="big split-lines">{aboutText}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
