import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function Tools() {
  useEffect(() => {
    const toolMarquee = document.querySelector('.all-tools');
    const marqueeDuration = toolMarquee.childElementCount * 1.5;
    toolMarquee.style.animationDuration = `${marqueeDuration}s`;
  });

  const data = useStaticQuery(graphql`
    {
      allSanityTools {
        nodes {
          title
          icon {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const allTools = data.allSanityTools.nodes;

  return (
    <>
      <section id="tools">
        <h4>Our Tech Stack</h4>
        <div className="tool-marquee">
          <div className="all-tools">
            {allTools.map((tool, i) => (
              <div className="tool" key={i}>
                <div className="img">
                  <GatsbyImage
                    image={tool.icon.asset.gatsbyImageData}
                    alt={tool.title}
                    objectFit="contain"
                  />
                </div>
                <h3>{tool.title}</h3>
              </div>
            ))}
            {allTools.map((tool, i) => (
              <div className="tool" key={i}>
                <div className="img">
                  <GatsbyImage
                    image={tool.icon.asset.gatsbyImageData}
                    alt={tool.title}
                    objectFit="contain"
                  />
                </div>
                <h3>{tool.title}</h3>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
