import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
/* eslint-disable */
import { Pagination, Autoplay, EffectCreative } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
/* eslint-enable */

export default function Projects() {
  const projectInterval = 6000;
  const projectSpeed = 800;
  const pagination = {
    el: '.project-pagination',
    clickable: true,
    renderBullet() {
      return `
        <div class="single-nav swiper-pagination-bullet pointer-cursor">
          <div class="nav-dot">
            <div class="inner-dot" style="animation-duration: ${
              projectInterval + projectSpeed
            }ms"></div>
          </div>
        </div>
      `;
    },
  };

  const data = useStaticQuery(graphql`
    {
      allSanityProjects(sort: { fields: order }) {
        nodes {
          title
          url
          color
          mainImage {
            alt
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const allProjects = data.allSanityProjects.nodes;

  return (
    <>
      <section id="projects" data-scroll-section>
        <Swiper
          className="all-projects"
          modules={[Pagination, Autoplay, EffectCreative]}
          grabCursor
          speed={projectSpeed}
          loop
          effect="creative"
          creativeEffect={{
            prev: {
              shadow: true,
              translate: ['-20%', 0, -1],
            },
            next: {
              translate: ['100%', 0, 0],
            },
          }}
          autoplay={{ delay: projectInterval, disableOnInteraction: false }}
          pagination={pagination}
        >
          {allProjects.map((project, i) => (
            <SwiperSlide
              className="project swipe-cursor"
              style={project.color && { backgroundColor: project.color }}
              key={i}
            >
              <div className="wrapper">
                <div className="text-content">
                  <span className="serial">{`0${i + 1}.`}</span>
                  <h2>{project.title}</h2>
                  <a
                    href={project.url}
                    target="_blank"
                    className="visit pointer-cursor"
                    rel="noreferrer"
                  >
                    Visit site
                  </a>
                </div>

                <div className="project-thumb">
                  <GatsbyImage
                    image={project.mainImage.asset.gatsbyImageData}
                    alt={project.mainImage.alt}
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}

          <div className="pagination">
            <div className="wrapper">
              <div className="project-pagination" />
            </div>
          </div>
        </Swiper>
      </section>
    </>
  );
}
